import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { SRLWrapper } from "simple-react-lightbox"

import Seo from "~/components/seo"
import PageHero from "~/components/page-hero"
import ShopLayout from "~/components/shop-layout"

import useWeddingPage from "~/hooks/use-wedding-page"

const Weddings = () => {
  const { pageHero, pageContent } = useWeddingPage()

  const options = {
    settings: {},
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false,
    },
    caption: {
      showCaption: false,
    },
  }

  return (
    <>
      <Seo
        title="Weddings"
        image={`https:${getSrc(pageContent.coverImage)}`}
        description="I believe that every wedding is and should be completely
                  unique! This means that I don’t have any ready-made wedding
                  packages to choose from but instead will quote each wedding
                  individually, working with you to create something you’re
                  completely happy with - no matter your budget!"
      />

      <PageHero title={pageHero.title} image={pageHero.coverImage} />

      <ShopLayout>
        <div className="w-full">
          <div className="prose xl:prose-lg mx-auto">
            <p>
              I believe that every wedding is and should be completely unique!
              This means that I don’t have any ready-made wedding packages to
              choose from but instead will quote each wedding individually,
              working with you to create something you’re completely happy with
              - no matter your budget!
            </p>
            <p>
              Please download the form below, fill it out and email it back to
              me at{" "}
              <a href="mailto:hello@heartbeeflowers.com">
                hello@heartbeeflowers.com
              </a>
              .
            </p>
            <p>
              This will give me a much clearer idea of what sort of florals
              you're after for your big day and the look you're going for. The
              more information you can give me the better, but don't worry if
              you don't have firm answers for some of the questions yet!
            </p>
            <p>
              Once I have had a look at your ideas, I will confirm whether or
              not I will be able to do your wedding and then we can get working
              on creating your dream florals!
            </p>
            <p>I look forward to hearing your ideas!</p>
          </div>
        </div>

        <div className="flex items-center justify-center mt-10">
          <a
            href={pageContent.document.file}
            className="bounce text-center block py-3 px-5 border border-transparent shadow rounded text-white text-md lg:text-lg font-medium bg-orange hover:bg-orange-dark disabled:opacity-50 focus:outline-none"
            download
          >
            {pageContent.document.buttonText}
          </a>
        </div>

        <div className="w-full mt-20">
          <SRLWrapper options={options}>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {pageContent.gallery.map(({ id, image, title }) => (
                <GatsbyImage
                  image={image}
                  key={id}
                  alt={title}
                  className="cursor-pointer rounded-md"
                />
              ))}
            </div>
          </SRLWrapper>
        </div>
      </ShopLayout>
    </>
  )
}

export default Weddings
